import { apitrigger, axiosFunc } from "../shared/common";
import config from '../config'

export const fetchcms =async () => {
return await apitrigger(`${config.BACK_URL}/cms/cmslist` , "GET")
}

const HandelAxiosError = (err) => {
    if(err.response.status >= 400 && err.response.status < 500){
        if(err.response.status == 422){
         localStorage.clear();  
         return err.response.data     
        }
         return { status : false , message : err.response.data.error}   
     }
     return { status : false , message : 'please try later '}
}

export const Currency    =   async   (data)  =>  {
 
       const senddata ={
        'method': 'get',
        'url': `${config.BACK_URL}/cms/currencylist`,  
        'params' : {type : "nft" }
    }
       let Resp = await axiosFunc(senddata);
    
    return Resp.data;
}

export const USDPRICE = async (data) =>{
    var senddata ={
        'method': 'get',
        'url': `https://min-api.cryptocompare.com/data/price?fsym=${data}&tsyms=USD`,  
    }
    let Resp = await axiosFunc(senddata);
    
    return Resp.data?.USD;
}


export const TOKENPRICE = async (data) =>{
    var senddata ={
        'method': 'get',
        'url': `https://api.pancakeswap.info/api/v2/tokens/${data}`,  
    }
    let Resp = await axiosFunc(senddata);
    
    return Resp?.data?.data?.price;
}


export const UploadImageApi = async(data) =>{
    try{
        let resp = await axiosFunc({
          
                'method':'Post',
                'url':`${config.BACK_URL}/game/uploadimage`, 
                data : data ,
                "headers":{
                    'Content-Type': 'multipart/form-data',
                    "Authorization": localStorage.getItem("token")
                },
        })
      

        return resp.data;
    }
    catch(err){
        return HandelAxiosError(err)
}

}