import React, { useEffect, useState } from "react";
import { Container, Button, Modal, Dropdown } from 'react-bootstrap';
import crewimg from "../Assets/crewimg.png";
import eth from "../Assets/eth.png";

function Stakemodals(props) {

    const [stakesmodal, setStakesmodal] = useState(true);

    return (

        <div>
            <Modal show={stakesmodal}  className="placemodal" size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton onClick={() => props.onDismiss()}></Modal.Header>
                <Modal.Body className='px-3 px-sm-5'>
                
                    <div className="text-center mt-3 mb-4"><img src={crewimg} className="img-fluid" /></div>
                    <h4 className="whtsclr fw-600">Crewmate #3140</h4>

                    <div className="d-flex justify-content-between pt-2">
                        <div><p className="whtsclr f-18 fw-600 mb-2">Reward per day</p><p className="whtsclr f-18 fw-300 mb-2">0.002 <img src={eth} className="img-fluid bnb ms-2" alt="img" /> ETH</p></div>
                        <div><p className="whtsclr f-18 fw-600 mb-2">Rewards %</p><p className="whtsclr f-18 fw-300 mb-2">0.2 %</p></div>
                    </div>

                    <div className="d-flex justify-content-center pb-4">
                        <Button className="explorefull mt-4 me-3">Stake</Button>
                        <Button className="explore mt-4" onClick={() => props.onDismiss()}>Cancel</Button>
                    </div>


                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Stakemodals;